import {
  BusinessArea,
  CertificateType,
  DestinationMarket,
  ImportCertificateType,
  Optionality,
  TemplateVersionStatus,
} from "@tc/graphql-server"
import { MenuItemKeyValuePair, SearchFieldId, TemplateType } from "../types"

export const defaultDateFormat = "DD/MMM/YYYY"
export const defaultDateTimeFormat = "DD/MMM/YYYY, HH:mm:ss"
export enum DateTimeFormat {
  Timezone = `${defaultDateTimeFormat} z`,
}
export const defaultTimezone = "Pacific/Auckland"
export const defaultLocale = "en-NZ"
export const importCertificateSearchPage = "/import-certificates"
export const businessRuleSearchPage = "/business-rules/search"
export const templateSearchPage = "/search"

export const DateFilterLabels: Record<string, string> = {
  expiry: "Expiry Date",
  effective: "Effective Date",
  lastEdited: "Last Edited Date",
  effectiveDateStart: "Effective Start",
  effectiveDateEnd: "Effective End",
  expiryDateStart: "Expiry Start",
  expiryDateEnd: "Expiry End",
  lastEditedDateStart: "Last edited Start",
  lastEditedDateEnd: "Last edited End",
}

export const BusinessAreas: Record<BusinessArea, string> = {
  [BusinessArea.Dairy]: "Dairy",
  [BusinessArea.Honey]: "Honey",
  [BusinessArea.LiveAnimals]: "Live Animals",
  [BusinessArea.Meat]: "Meat",
  [BusinessArea.Organics]: "Organics",
  [BusinessArea.Other]: "Other",
  [BusinessArea.Plants]: "Plants",
  [BusinessArea.Seafood]: "Seafood",
  [BusinessArea.Seed]: "Seed",
  [BusinessArea.Wine]: "Wine",
}

export const Statuses: Record<TemplateVersionStatus, string> = {
  [TemplateVersionStatus.Approved]: "Approved",
  [TemplateVersionStatus.Cancelled]: "Cancelled",
  [TemplateVersionStatus.Draft]: "Draft",
  [TemplateVersionStatus.Locked]: "Locked",
  [TemplateVersionStatus.Review]: "Review",
  [TemplateVersionStatus.Revoked]: "Revoked",
  [TemplateVersionStatus.Suspended]: "Suspended",
}

export const CertificateTypes: Record<CertificateType, string> = {
  [CertificateType.FreeSale]: "Free Sale",
  [CertificateType.FreeSaleStatement]: "Free Sale Statement",
  [CertificateType.LiveAnimals]: "Live Animals",
  [CertificateType.Organics]: "Organics",
  [CertificateType.ExportEligibilityStatement]: "Export Eligibility Statement",
  [CertificateType.Vi_1]: "VI-1 Certificate",
}

export const ImportCertificateTypes: Record<ImportCertificateType, string> = {
  [ImportCertificateType.ImportedPhytosanitary]: "Phytosanitary",
  [ImportCertificateType.ImportedSanitary]: "Sanitary",
  [ImportCertificateType.ReexportedPhytosanitary]: "Re-Export Phytosanitary",
}

export const DestinationMarkets: Record<DestinationMarket, string> = {
  [DestinationMarket.Australia]: "Australia",
  [DestinationMarket.China]: "China",
  [DestinationMarket.GreatBritain]: "Great Britain",
  [DestinationMarket.Japan]: "Japan",
  [DestinationMarket.NotApplicable]: "Not Applicable",
  [DestinationMarket.Singapore]: "Singapore",
  [DestinationMarket.Taiwan]: "Taiwan",
  [DestinationMarket.Brazil]: "Brazil",
  [DestinationMarket.Eu]: "EU",
}

export const OptionalityLabels: Record<Optionality, string> = {
  [Optionality.Disabled]: "Disabled",
  [Optionality.Optional]: "Optional",
  [Optionality.Required]: "Required",
}

export const exportCertificateWorkflowId = "exportCertificateWorkflow"
export const productFieldsId = "productFields"
export const ippcConnectionsId = "importConnections"
export const wineAnalytesTolerancesId = "wineAnalytesTolerances"
export const declarationsId = "declarations"

export const FieldLabels: Record<string, string> = {
  Expire: "Expiry Date",
  Effective: "Effective Date",
  "Destination Market": "Destination Markets",
  Title: "Template Title",
  Code: "Template Code",
  "Template Version": "Version",
}

export const YesNoKeyValuePairs: MenuItemKeyValuePair[] = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
]

export const TemplateTypes: Record<TemplateType, string> = {
  [TemplateType.Export]: "Export",
  [TemplateType.Import]: "Import",
}

// Below collections are small data set that has less than 250 records
// Business Rules can contain multiple search fields and also hundred of records so caching it in client side can reduce multiple search as a service network round trip
export const SearchFieldCollectionMappings: Record<SearchFieldId, string> = {
  [SearchFieldId.CertificateType]: "certificate-types",
  [SearchFieldId.DestinationMarket]: "destination-markets",
  [SearchFieldId.Commodity]: "commodity-types",
  [SearchFieldId.FieldId]: "product-fields",
  [SearchFieldId.CountryCode]: "countries",
  [SearchFieldId.WineCategory]: "wine-eu-categories",
  [SearchFieldId.WineChemistryTest]: "wine-chem-tests",
}
