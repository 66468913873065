import {
  DecisionTableDataType,
  DecisionTableRevision,
  DecisionTableRow,
  RulesInput,
} from "@tc/graphql-server"
import { Dayjs } from "dayjs"
import { FieldPath, FieldValues, RegisterOptions } from "react-hook-form"

export interface MenuItemKeyValuePair {
  label: string
  value: string
}

export enum RowState {
  Initial = "INITIAL",
  New = "NEW",
}

//TODO update backend to add DATE data type so can reuse
export enum CustomDataType {
  Date = "DATE",
  Action = "ACTIONS",
  Hidden = "HIDDEN",
}

export type RulesInputModel = Omit<RulesInput, "validFrom" | "validUntil"> & {
  validFrom?: Dayjs
  validUntil?: Dayjs
}

type DecisionTableRowFormOriginalModel = Omit<
  DecisionTableRow,
  "__typename" | "sequence" | "input"
>

export type DecisionTableRowFormModel =
  Partial<DecisionTableRowFormOriginalModel> & {
    input: RulesInputModel
    state: RowState
    validFromInitial: Dayjs
    validUntilInitial: Dayjs
  }

export type DecisionTableRevisionFormModel = Pick<
  DecisionTableRevision,
  "title" | "description"
> & {
  rows: DecisionTableRowFormModel[]
}

export type TableCellDataType = DecisionTableDataType | CustomDataType

export interface TableCellBaseColDef {
  name: string
  type: TableCellDataType
  headerName: string
}

export interface TableCellBaseInputColDef<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  rules?: Omit<
    RegisterOptions<TFieldValues, TName>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >
}

export interface FieldProps {
  required: boolean
  disabled: boolean
  value?: string
}

export interface TableCellInputColDef
  extends TableCellBaseColDef,
    TableCellBaseInputColDef,
    FieldProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

// TODO extends TableCellSelectColDef so can have option field
export interface TableCellSearchColDef
  extends TableCellInputColDef,
    TableCellBaseInputColDef {
  collection: string
  multiple: boolean
  searchAsYouType: boolean
  apiKeyMutation: string
  typesenseBaseUrl: string
}

export interface TableCellSelectColDef
  extends TableCellInputColDef,
    TableCellBaseInputColDef {
  options: MenuItemKeyValuePair[]
  multiple: boolean
}

export interface TableCellActionButtonColDef extends TableCellBaseColDef {
  onClick: (index: number) => void
}

//TODO cannot import Document and SearchParameters from typesense-instantsearch-adapter
export interface Document {
  id: string
  label: string
}

type OperationMode = "off" | "always" | "fallback"
export interface SearchParameters {
  q: string
  query_by: string | string[]
  per_page?: number
  infix?: OperationMode
}

export enum SearchFieldId {
  CertificateType = "CertificateType",
  DestinationMarket = "DestinationMarket",
  Commodity = "Commodity",
  FieldId = "FieldId",
  CountryCode = "CountryCode",
  WineCategory = "WineCategory",
  WineChemistryTest = "WineChemistryTest",
}

export enum TemplateType {
  Export = "Export",
  Import = "Import",
}

export enum DownloadImportCertificatePdfReason {
  RequiredForReexport = "Required for re-export of product",
  RequiredForOcaReview = "Required for OCA review",
  Other = "Other",
}
